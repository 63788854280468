/* You can add global styles to this file and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@swimlane/ngx-datatable/release/themes/bootstrap.css";

/* Bootstrap overriding */
kbd,
.table .thead-dark th,
.table-dark {
  background-color: #222420;
}

.btn-light,
.btn-light.disabled, .btn-light:disabled,
.btn-outline-light,
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle,
.dropdown-item:hover, .dropdown-item:focus,
.badge-light,
.list-group-item-action:hover, .list-group-item-action:focus,
.bg-light {
  background-color: #f9faf8;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  background-color: transparent;
}

.nav-pills .nav-link:not(active):not(.active):hover,
.list-group-item-action:active {
  background-color: #e9ecef;
}

.btn-light,
.btn-light.disabled, .btn-light:disabled,
.btn-outline-light,
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  border-color: #f9faf8;
}

.border-light {
  border-color: #f9faf8;
}

body,
pre,
.form-control-plaintext,
.btn-warning,
.btn-warning:hover,
.btn-warning.disabled, .btn-warning:disabled,
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle,
.btn-light,
.btn-light:hover,
.btn-light.disabled, .btn-light:disabled,
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle,
.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle,
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle,
.dropdown-menu,
.dropdown-item,
.dropdown-header,
.dropdown-item-text,
.badge-warning,
.badge-warning[href]:hover, .badge-warning[href]:focus,
.badge-light,
.badge-light[href]:hover, .badge-light[href]:focus,
.list-group-item-action:active,
.popover-body {
  color: #222420;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f9faf8;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}

body, html {
  font-size: 1rem;
}

/* Small devices (sm) (landscape phones, 576px and up) */
@media (min-width: 576px) {
  body, html {
    font-size: 1.01rem;
  }
  body > app-root > app-header > nav,
  body > app-root > app-footer > footer {
    font-size: 1rem;
  }
}

/* Medium devices (md) (tablets, 768px and up) */
@media (min-width: 768px) {
  body, html {
    font-size: 1.02rem;
  }
}

/* Large devices (lg) (desktops, 992px and up) */
@media (min-width: 992px) {
  body, html {
    font-size: 1.03rem;
  }
  body > app-root > app-header > nav,
  body > app-root > app-footer > footer {
    font-size: 1rem;
  }
}

/* Extra large devices (xl) (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body, html {
    font-size: 1.04rem;
  }
  body > app-root > app-header > nav,
  body > app-root > app-footer > footer {
    font-size: 1rem;
  }
}
/* END of Bootstrap overriding */


/* ablioconference own styles */
.btn-xs, .btn-group-xs > .btn {
  border-radius: 0.175rem;
  font-size: 0.6rem;
  line-height: 1.2;
  padding: 0.1rem 0.3rem;
}

.btn-xs + .dropdown-toggle-split, .btn-group-xs > .btn + .dropdown-toggle-split {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.card-header-ext {
  margin-left: -15px;
  margin-right: -15px;
  padding: 1.25rem calc(15px + 1.25rem);
}

.border-ablio {
  border-color: #f79b2f !important;
}

.border-ablio-green {
  border-color: #1abc9c !important;
}

/*
.mmediawrapper {
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 25px;
  position: relative;
}
*/
.mmediawrapper .embed-responsive-item,
.mmediawrapper iframe,
.mmediawrapper embed,
.mmediawrapper object,
.mmediawrapper video {
  height: auto;
/*
  left: 0;
  position: absolute;
  top: 0;
*/
  width: 100%;
}

.text-light {
  color: #f9faf8 !important;
}

.text-body {
  color: #222420 !important;
}

/* Google Fonts alternatives: Muli, Questrial, Lato */
body, h1, h2, h3, h4, h5, h6, p, blockquote, .p-alike {
  font-family: "Didact Gothic", "Century Gothic", "URW Gothic L", "Avant Garde", Futura, Questrial, Muli, "Futura PT", Helvetica, Arial, CenturyGothic, AppleGothic, sans-serif;
}

.text-overline {
  text-decoration: overline;
}

.text-overunderline {
  text-decoration: overline underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-decoration-underline {
  text-decoration: underline;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"],
.clickable:not(:disabled):not(.disabled),
.datatable-clickable div .datatable-body datatable-selection .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-group .datatable-body-cell,
.datatable-clickable div .datatable-body datatable-selection .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-group .datatable-body-cell .datatable-body-cell-label,
.datatable-clickable div .datatable-body datatable-selection .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-group .datatable-body-cell .datatable-body-cell-label span {
  cursor: pointer;
}

button[disabled],
input[disabled],
select[disabled],
.clickable[disabled],
.disabled,
.unclickable {
  cursor: not-allowed !important;
}

.flash-messages-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.flash-messages-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.flash-messages-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.flash-messages-warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}

.flash-messages-danger, .flash-messages-info, .flash-messages-success, .flash-messages-warning {
  left: 15%;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
  width: 70%;
}

.flash-messages-center {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

#flashMessages > div.alert.flash-message > div {
  margin-bottom: 0.75rem;
}

#grayOutDiv {
  background-color: #333;
  height: 100%;
  left: 0;
  opacity: 0.7;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.flash-message {
  position: relative;
  z-index: 10000;
}

header .jumbotron {
  /*background-color: #e9ecef;*/
  padding: 2rem;
}

/*
div.header {
  background: linear-gradient(#ffffff, #f9faf8);
}
*/

.main-title-gradient {
  /*background: linear-gradient(to right, #e9e9e9, #ffffff);*/
  background: linear-gradient(#f9faf8, #ffffff);
  padding-bottom: 3em;
  padding-top: 3em;
}

.main-title-gradient h1 {
  text-align: center;
}

.text-conference {
  color: #222420;
}

a.text-conference:hover,
a.text-conference:focus {
  color: #1d2124 !important;
}

.bg-ablio {
  background-color: #f79b2f;
}

.bg-ablio-light {
  background-color: #fce1c1;
}

.bg-ablio-green {
  background-color: #1abc9c;
}

.bg-ablio-green-light {
  background-color: #baebe1;
}

.text-ablio {
  color: #f79b2f;
}

.text-ablio-green {
  color: #1abc9c;
}

hr.ablio {
  border-top-color: #f79b2f;
}

hr.ablio-green {
  border-top-color: #1abc9c;
}

hr.thin {
  border-top-width: 1px;
  height: 1px;
}

hr.medium {
  border-top-width: 3px;
  height: 3px;
}

hr.tick {
  border-top-width: 7px;
  height: 7px;
}

.btn-ablio,
.btn-ablio:hover,
.btn-ablio:disabled,
.btn-ablio.disabled,
.btn-ablio:not(:disabled):not(.disabled):active,
.btn-ablio:not(:disabled):not(.disabled).active,
.show > .btn-ablio.dropdown-toggle,
.btn-ablio-green,
.btn-ablio-green:hover,
.btn-ablio-green:disabled,
.btn-ablio-green.disabled,
.btn-ablio-green:not(:disabled):not(.disabled):active,
.btn-ablio-green:not(:disabled):not(.disabled).active,
.show > .btn-ablio-green.dropdown-toggle {
  color: #fff;
}

.btn-ablio,
.btn-ablio:disabled,
.btn-ablio.disabled {
  background-color: #f79b2f;
  border-color: #f79b2f;
}

.btn-ablio:hover {
  background-color: #e88200;
  border-color: #d37800;
}

.btn-ablio:focus,
.btn-ablio.focus,
.btn-ablio:not(:disabled):not(.disabled):active:focus,
.btn-ablio:not(:disabled):not(.disabled).active:focus,
.show > .btn-ablio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 155, 47, 0.5);
}

.btn-ablio-green:not(:disabled):not(.disabled):active,
.btn-ablio-green:not(:disabled):not(.disabled).active,
.show > .btn-ablio-green.dropdown-toggle {
  background-color: #cb5800;
  border-color: #b85200;
}

.btn-ablio-green,
.btn-ablio-green:disabled,
.btn-ablio-green.disabled {
  background-color: #1abc9c;
  border-color: #1abc9c;
}

.btn-ablio-green:hover {
  background-color: #139d8f;
  border-color: #0d928b;
}

.btn-ablio-green:focus,
.btn-ablio-green.focus,
.btn-ablio-green:not(:disabled):not(.disabled):active:focus,
.btn-ablio-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-ablio-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 188, 156, 0.5);
}

.btn-ablio-green:not(:disabled):not(.disabled):active,
.btn-ablio-green:not(:disabled):not(.disabled).active,
.show > .btn-ablio-green.dropdown-toggle {
  /*background-color: #15988f;*/
  background-color: #139d8f;
  /*border-color: #138e88;*/
  border-color: #0d928b;
  /*color: #f79b2f;*/
  font-weight: bold;
}

.btn-outline-ablio,
.btn-outline-ablio-green {
  background-color: transparent;
  background-image: none;
}

.btn-outline-ablio {
  border-color: #f79b2f;
  color: #f79b2f;
}

.btn-outline-ablio:hover,
.btn-outline-ablio:not(:disabled):not(.disabled):active, .btn-outline-ablio:not(:disabled):not(.disabled).active,
.show > .btn-outline-ablio.dropdown-toggle {
  background-color: #f79b2f;
  border-color: #f79b2f;
  color: #e88200;
}

.btn-outline-ablio.disabled, .btn-outline-ablio:disabled {
  background-color: transparent;
  color: #f79b2f;
}

.btn-outline-ablio:focus, .btn-outline-ablio.focus,
.btn-outline-ablio:not(:disabled):not(.disabled):active:focus, .btn-outline-ablio:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-ablio.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 155, 47, 0.5);
}

.btn-outline-ablio-green {
  border-color: #1abc9c;
  color: #1abc9c;
}

.btn-outline-ablio-green:hover,
.btn-outline-ablio-green:not(:disabled):not(.disabled):active, .btn-outline-ablio-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-ablio-green.dropdown-toggle {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #139d8f;
}

.btn-outline-ablio-green.disabled, .btn-outline-ablio-green:disabled {
  background-color: transparent;
  color: #1abc9c;
}

.btn-outline-ablio-green:focus, .btn-outline-ablio-green.focus,
.btn-outline-ablio-green:not(:disabled):not(.disabled):active:focus, .btn-outline-ablio-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-ablio-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 188, 156, 0.5);
}

.table-ablio,
.table-ablio > th,
.table-ablio > td {
  background-color: #f79b2f;
  color: #fff;
}

.table-hover .table-ablio:hover,
.table-hover .table-ablio:hover > td,
.table-hover .table-ablio:hover > th {
  background-color: #e5941f;
}

.alert-ablio {
  background-color: #fff3cd;
  border-color: #f79b2f;
  color: #856404;
}

.alert-ablio hr {
  border-top-color: #e5941f;
}

.alert-ablio .alert-link {
  color: #533f03;
}

.alert-ablio-green {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-ablio-green hr {
  border-top-color: #b1dfbb;
}

.alert-ablio-green .alert-link {
  color: #0b2e13;
}

/* TODO:
.list-group-item-ablio {
  background-color: #ffeeba;
  color: #856404;
}

.list-group-item-ablio.list-group-item-action:hover, .list-group-item-ablio.list-group-item-action:focus {
  background-color: #e5941f;
  color: #856404;
}

.list-group-item-ablio.list-group-item-action.active {
  background-color: #856404;
  border-color: #856404;
  color: #fff;
}

.list-group-item-ablio-green {
  background-color: #ffeeba;
  color: #856404;
}

.list-group-item-ablio-green.list-group-item-action:hover, .list-group-item-ablio-green.list-group-item-action:focus {
  background-color: #e5941f;
  color: #856404;
}

.list-group-item-ablio-green.list-group-item-action.active {
  background-color: #856404;
  border-color: #856404;
  color: #fff;
}
*/

.badge-ablio {
  background-color: #f79b2f;
}

.badge-ablio[href]:hover, .badge-ablio[href]:focus {
  background-color: #d37800;
}

.badge-ablio-green {
  background-color: #1abc9c;
}

.badge-ablio-green[href]:hover, .badge-ablio-green[href]:focus {
  background-color: #0d928b;
}

.badge-ablio,
.badge-ablio-green,
.badge-ablio[href]:hover, .badge-ablio[href]:focus,
.badge-ablio-green[href]:hover, .badge-ablio-green[href]:focus {
  color: #fff;
}

.badge-ablio[href]:hover, .badge-ablio[href]:focus,
.badge-ablio-green[href]:hover, .badge-ablio-green[href]:focus {
  text-decoration: none;
}

a.dropdown-item.active > span.text-ablio,
a.dropdown-item.active > span.text-ablio-green,
a.list-group-item.active > span.text-ablio,
a.list-group-item.active > span.text-ablio-green,
.datatable-body-row.active a {
  color: #fff;
}

#loading {
  display: block;
  margin: 0 auto;
}

#accordion .card .card-header h5 .btn.btn-block.btn-link {
  font-weight: bold;
  text-align: left;
}

.text.monospace, .text-monospace-pre {
  font-family: Menlo, Consolas, 'Courier New', monospace;
}
.text-pre, .text-monospace-pre {
  white-space: pre;
}
.text-pre-wrap, .text-monospace-pre-wrap {
  white-space: pre-wrap;
}

#accordion .card .card-header h5 .btn.btn-block.btn-link,
.text-pre-wrap {
  white-space: pre-wrap;
}

.lead strong,
.lead b,
.lead .font-weight-bold {
  font-weight: bold;
}

p {
  text-align: justify;
}

p.text-center {
  text-align: center;
}

p.text-left {
  text-align: left;
}

p.text-right {
  text-align: right;
}

.dbstats {
/*
  background: #222;
  color: #aaa;
  font-family: Menlo, Consolas, 'Courier New', monospace;
*/
  max-height: 440px;
  overflow-y: auto;
  white-space: pre-wrap;
}

/*
#fixed-tabs .list-group-item.list-group-item-action {
  width: 150px;
}
*/

div.border-ablio.border-right {
    border-right-width: 7px !important;
}

/* form-range (bs5) and slider */
.form-range,
.slider {
  appearance: none;
  background: #d3d3d3;
  border-radius: 5px;
  height: 15px;
  opacity: 0.7;
  outline: none;
  transition: opacity .2s;
  width: 100%;
}

.form-range:hover,
.slider:hover {
  opacity: 1;
}

.form-range::-moz-range-thumb,
.form-range::-webkit-slider-thumb,
.slider::-moz-range-thumb,
.slider::-webkit-slider-thumb {
  appearance: none;
  background: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

/* eXtra Small devices (landscape phones, less than 576px) */
@media screen and (max-width: 575.98px) {
  div.border-ablio.border-right {
    border: 0 transparent none;
  }
}

@media screen and (min-width: 576px) {
  .dashboard-content > * > div.container,
  .dashboard-content > * > * > div.container {
    margin-left: 0;
  }
}

@media screen and (max-width: 1091.98px) {
  /* Intercom chat when there is no footer */
  .mbi {
    margin-bottom: 90px;
  }
}

.blink {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: blink;
  animation-timing-function: ease-in-out;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}
